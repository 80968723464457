define("discourse/plugins/discourse-code-review/discourse/connectors/user-preferences-notifications/notify-code-review", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      const user = args.model;
      this.set("notifyOnCodeReviews", user.custom_fields.notify_on_code_reviews !== false);
      component.addObserver("notifyOnCodeReviews", () => {
        user.set("custom_fields.notify_on_code_reviews", component.get("notifyOnCodeReviews"));
      });
    },
    shouldRender(args, component) {
      return component.currentUser && component.currentUser.admin;
    }
  };
});