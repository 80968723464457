define("discourse/plugins/discourse-code-review/discourse/templates/connectors/user-activity-bottom/approval-given-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo @route="userActivity.approval-given">
    {{i18n "code_review.approval_given"}}
  </LinkTo>
  */
  {
    "id": "FgRS2RIW",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"userActivity.approval-given\"]],[[\"default\"],[[[[1,\"\\n  \"],[1,[28,[35,1],[\"code_review.approval_given\"],null]],[1,\"\\n\"]],[]]]]]],[],false,[\"link-to\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-code-review/discourse/templates/connectors/user-activity-bottom/approval-given-button.hbs",
    "isStrictMode": false
  });
});