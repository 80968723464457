define("discourse/plugins/discourse-code-review/discourse/templates/connectors/user-preferences-notifications/notify-code-review", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="control-group">
    <label class="control-label">{{i18n "code_review.title"}}</label>
    <div class="controls">
      <PreferenceCheckbox
        @labelKey="code_review.notify_on_approval"
        @checked={{notifyOnCodeReviews}}
      />
    </div>
  </div>
  */
  {
    "id": "jRyNmx33",
    "block": "[[[10,0],[14,0,\"control-group\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,0],[\"code_review.title\"],null]],[13],[1,\"\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@labelKey\",\"@checked\"],[\"code_review.notify_on_approval\",[30,0,[\"notifyOnCodeReviews\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `notifyOnCodeReviews` property path was used in the `discourse/plugins/discourse-code-review/discourse/templates/connectors/user-preferences-notifications/notify-code-review.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.notifyOnCodeReviews}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"i18n\",\"preference-checkbox\"]]",
    "moduleName": "discourse/plugins/discourse-code-review/discourse/templates/connectors/user-preferences-notifications/notify-code-review.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});